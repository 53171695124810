import colors from '../../../themes/main-colors';

export const subText1 = {
  fontSize: '20px',
  marginBottom: 20,
  fontFamily: 'Forma-DJR-Display',
  letterSpacing: '0.4px',
};
export const requiredText = {
  font: 'italic normal normal 14px/18px Proxima Nova',
  color: colors.noirBlurLight,
  marginTop: '20px',
};

export const boldText = {
  fontWeight: 600,
  margin: 0,
  fontSize: '20px',
};
export const subTextWithoutMargin = {
  fontSize: '16px',
  lineHeight: '18px',
  marginBottom: '4px',
};
export const dropdownTitle = {
  fontSize: 16,
};
export const smartIcon = {
  height: '10px',
  width: '10px',
};
export const mainContactFields = {
  width: '100%',
  margin: '8px 0 8px 0',
};
